import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello developers,`}</p>
    <p>{`Whenever you are writing documentation for your current project or just making the next blog posts about Software Development, you might need to take screenshots of your code.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/sisCmhfe28A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`How can you grab a screenshot of your own codebase in an easy way and without effort?`}</p>
    <h2>{`Carbon`}</h2>
    <p>{`There are several ways and for sure, one of the most common site to make a screenshot of your own codebase is a website called `}<a parentName="p" {...{
        "href": "https://carbon.now.sh/"
      }}>{`Carbon`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--0hrEjNad--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/wf6zikg0jpjcg8foauqx.png",
        "alt": "Image"
      }}></img></p>
    <p>{`I used this site a lot in the past and it is actually quite good.
You copy your code on this webpage and you can customize fonts, syntax highlights, background color, export the image to PNG or SVG and much more.`}</p>
    <p>{`Great site, used a lot and highly recommended.`}</p>
    <p>{`The only thing with this site is that you have your own code on your editor already. You have to copy your code on this external site and adjust the look & feel to make it look right on your social media.`}</p>
    <p>{`But wait...`}</p>
    <p>{`The code is already on my editor, Visual Studio Code.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--8xbkepvi--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/95ih5a6h9ku3zyucydd5.png",
        "alt": "Image"
      }}></img></p>
    <p>{`I have already everything set up on the editor: fonts, theme and how my code will look & feel.`}</p>
    <p>{`What if you could take a screenshot of your own codebase using your own editor without using the need to copy-paste the codebase on external sites?`}</p>
    <h2>{`POLACODE`}</h2>
    <p>{`Enter `}<b>{`Polacode`}</b>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--Kwd-yuY4--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/1jdkn9nybp8ecs4mod2x.png",
        "alt": "Image"
      }}></img></p>
    <p>{`An extension for Visual Studio Code to take a screenshot from your own editor.`}</p>
    <p>{`Let's see how to install it and use it.`}</p>
    <h3>{`INSTALL THE EXTENSION`}</h3>
    <p>{`Just install it from your marketplace. Search for "Polacode" in the Visual Studio Marketplace and click the install button.`}</p>
    <h3>{`CONFIGURE IT`}</h3>
    <p>{`Configure the extension: there are not many settings.
For example, you can decide if you want a color for your background as well as using a transparent background.`}</p>
    <h3>{`SCREENSHOT`}</h3>
    <p>{`Find the codebase that you want to take a screenshot of.
Open the Command Menu in VSCode (`}<b>{`Command + Shift + P `}</b>{`on the Mac), and write Polacode.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--SQYSaSRe--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/fcbt6s88vxoybtwfdrth.png",
        "alt": "Image"
      }}></img></p>
    <p>{`A window will open side-by-side to your own code. This is the preview of the code that will appear in the final image.`}</p>
    <p>{`Highlight the code that you want in your image and you are done.
The code will appear in the preview while you are highlighting it.`}</p>
    <p>{`When you are happy with what you see, click that Screenshot button and you are done. A popup will appear to ask you where to save the final image.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/practicaldev/image/fetch/s--oEeeDbX8--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/aketit1t00ekjxwktsp6.png",
        "alt": "Image"
      }}></img></p>
    <p>{`Yes, it is that easy! Pretty amazing, uh?`}</p>
    <h2>{`WHAT ARE YOUR THOUGHTS?`}</h2>
    <p>{`This really shortens the time for getting your screenshots out when working on both personal branding on Instagram and on my blog posts.`}</p>
    <p>{`Tell me: what is your favorite VSCode extension and why?`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/sisCmhfe28A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`Well, the last thing: If you arrived here, thank you so much for your support and time that you spend on this page.`}</p>
    <p>{`If you enjoyed this story, please click the like button and share it to help others find it! Feel free to leave a comment below.`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      